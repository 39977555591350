import { makeFormRequest, makeGetRequest } from '@/api/helpers';
import API from './../config/api-routers';

export const getSeoDetail = async (slug: string | number) => {
    const result = await makeGetRequest(`${API.seo.get}?page=1&filters[0][id]=rewrite&filters[0][value]=${slug}`);

    return result.data.result.table.rows[0];
};

export const updateSeo = async (data: any) => {
    const result = await makeFormRequest(API.seo.update, data);

    return result;
};
