import InputEntity from '@/lib/formFactory/inputEntity';

export default class EntityModel {
    model: any;

    constructor() {
        const titleEntity = new InputEntity('title');
        titleEntity.setTitle('Заголовок (title)');

        const keywordsEntity = new InputEntity('keywords');
        keywordsEntity.setTitle('Ключевые слова (keywords)');

        const descriptionEntity = new InputEntity('description');
        descriptionEntity.setTitle('Описание (description)');

        this.model = {
            title: titleEntity.entity[titleEntity.entityName],
            keywords: keywordsEntity.entity[keywordsEntity.entityName],
            description: descriptionEntity.entity[descriptionEntity.entityName],
        };
    }
}
