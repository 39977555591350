export const seoMenu = [
    {
        title: 'Контентая часть',
        items: [
            { name: 'Главная страница', query: 'main' },
            { name: 'О площадке', query: 'about' },
            { name: 'Правила площадки', query: 'rules' },
            { name: 'Пользовательское соглашение', query: 'terms' },
            { name: 'Политика конфиденциальности', query: 'privacy' },
            { name: 'Публичная оферта', query: 'offer' },
            { name: 'FAQ', query: 'faq' },
        ]
    },
    {
        title: 'Каталоги',
        items: [
            { name: 'Авторы', query: 'painters' },
            { name: 'Галереи', query: 'galleries' },
            { name: 'Аукционные дома', query: 'auction-houses' },
            { name: 'Ближайшие аукционы', query: 'upcoming-auctions' },
            { name: 'Прошедшие аукционы', query: 'past-auctions' },
        ],
    },
    {
        title: 'Авторизация',
        items: [
            { name: 'Авторизация и регистрация', query: 'auth' },
            { name: 'Регистрация', query: 'register' },
            { name: 'Регистрация завершена', query: 'register-confirm' },
        ],
    },
    {
        title: 'Личный кабинет',
        items: [
            { name: 'Профиль', query: 'personal-profile' },
            { name: 'Мои покупки', query: 'personal-orders' },
            { name: 'Мои аукционные дома', query: 'personal-auction-houses' },
            { name: 'Мои аукционы', query: 'personal-auctions' },
            { name: 'Ставки на торгах', query: 'personal-bets' },
            { name: 'Платежная информация', query: 'personal-billing' },
            { name: 'Избранное', query: 'personal-favorite' },
            { name: 'Подписки', query: 'personal-subscriptions' },
        ],
    },
    {
        title: 'Другое',
        items: [
            { name: 'Результаты поиска', query: 'search-result' },
            { name: 'Присоединиться', query: 'join' },
            { name: 'Новости', query: 'news' },
            { name: 'Поиск', query: 'lots' },
        ],
    },
];
