
import { Vue, Component } from 'vue-property-decorator';
import { seoMenu } from './list';

import Layout from '@/admin/layouts/Default.vue';
import Loading from '@/components/Loading.vue';
import InputBlock from '@/components/form/InputMain.vue';
import TextareaBlock from '@/components/form/Textarea.vue';
import SeoEntity from '@/admin/store/seo/entity';

@Component({
    name: 'SeoBlock',
    components: {
        Layout,
        Loading,
        InputBlock,
        TextareaBlock,
    },
})
export default class Seo extends Vue {
    loading = true;
    btnLoading = false;

    get modelEnity() {
        return SeoEntity.model;
    }

    get list() {
        return seoMenu;
    }

    get page(): string {
        return this.$route.query.page as string;
    }

    updateTitle(value: string) {
        SeoEntity.updateTitle(value);
    }

    updateKeywords(value: string) {
        SeoEntity.updateKeywords(value);
    }

    updateDescription(value: string) {
        SeoEntity.updateDescription(value);
    }

    async init() {
        await SeoEntity.init(this.page);
    }

    async save() {
        this.btnLoading = true;

        await SeoEntity.save(this.page);

        this.btnLoading = false;
    }

    async created() {
        if (!this.page) {
            this.$router.push({ path: '/seo', query: { page: 'main' } });
        }

        await this.init();

        this.loading = false;
    }
}
