import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import EntityBaseModule from '@/admin/store/entity';
import EntityModel from './entityModel';

import store from '@/admin/store';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';

import { getApiErrorMessage } from '@/lib/utils/Utils';

import { updateSeo, getSeoDetail } from '@/admin/api/seo';

export const MODULE_NAME = 'seo';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SeoEntityModule extends EntityBaseModule {
    constructor(module: SeoEntityModule) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
    }

    @Mutation
    SET_TITLE(value: string) {
        this.model.title.value = value;
    }

    @Mutation
    SET_KEYWORDS(value: string) {
        this.model.keywords.value = value;
    }

    @Mutation
    SET_DESCRIPTION(value: string) {
        this.model.description.value = value;
    }

    @Action({ rawError: true })
    updateTitle(value: string) {
        if (value !== '') {
            this.context.commit('RESET_ERROR_BY_FIELD', 'title');
        }

        this.context.commit('SET_TITLE', value);
    }

    @Action({ rawError: true })
    updateKeywords(value: string) {
        if (value !== '') {
            this.context.commit('RESET_ERROR_BY_FIELD', 'keywords');
        }

        this.context.commit('SET_KEYWORDS', value);
    }

    @Action({ rawError: true })
    updateDescription(value: string) {
        if (value !== '') {
            this.context.commit('RESET_ERROR_BY_FIELD', 'description');
        }

        this.context.commit('SET_DESCRIPTION', value);
    }

    @Action({ rawError: true })
    async init(slug: string) {
        try {
            const result = await getSeoDetail(slug);

            if (!result) {
                return null;
            }

            const data = JSON.parse(result.seo);

            this.updateTitle(data.title);
            this.updateDescription(data.description);
            this.updateKeywords(data.keywords);
        } catch(error) {
            ResponseHandlerModule.showNotify({ message: getApiErrorMessage(error), type: 'fail' }); 
        }
    }
    @Action({ rawError: true })
    async validate() {
        const requiredFields = ['title', 'keywords', 'description'];

        this.setValidateState({ index: 'title', field: this.model.title });
        this.setValidateState({ index: 'keywords', field: this.model.keywords });
        this.setValidateState({ index: 'description', field: this.model.description });

        return requiredFields.filter((item) => this.model[item].error.class).length > 0;
    }

    @Action({ rawError: true })
    async save(slug: string) {
        const valid = await this.validate();

        if (valid) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' }); 

            return false;
        }

        try {
            const sendData = {
                title: this.model.title.value,
                description: this.model.description.value,
                keywords: this.model.keywords.value,
            }

            await updateSeo({
                rewrite: slug,
                seo: JSON.stringify(sendData),
            });

            ResponseHandlerModule.showNotify({ message: 'Сохранено', type: 'ok' });
        } catch(error) {
            ResponseHandlerModule.showNotify({ message: getApiErrorMessage(error), type: 'fail' });
        }
    }
}

export default getModule(SeoEntityModule);
